<header class="header default">
  <div class="header-section">
    <!--Container-->
    <div class="container">
      <nav class="navbar navbar-expand-lg header-navbar">
        <a class="navbar-brand navbar-logo scroll" [routerLink]="['./']">
          <img
            class="mb-0 logo-light"
            src="./assets/event/logo.png"
            style="width: 120px"
            alt=""
          />

          <img
            class="mb-0 logo-dark"
            src="./assets/event/logo.png"
            style="width: 100px"
            alt=""
          />
        </a>
        <button
          class="navbar-toggler btn-navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target=".nav-menu"
          aria-expanded="true"
          aria-label="Toggle navigation"
        >
          <span class="fa fa-bars"></span>
        </button>
        <div
          class="
            nav-menu
            collapse
            navbar-collapse navbar-collapse
            justify-content-end
            py-0
          "
          
        >
        <div class="row">
          <ul class="list-group  list-group-horizontal-lg" style="color: white;">
            <li class="list-group-item" style="background-color: #efe93e7c "><button type="button"  class="btn btn-link " style="color: black" [routerLink]="['/']" data-mdb-ripple-color="dark">Home</button></li>
            <li class="list-group-item"style="background-color: #efe93e7c " ><button type="button"  class="btn btn-link  " style="color: #1d3563"  data-mdb-ripple-color="dark" [routerLink]="['./', 'speakers']">Speakers</button></li>
           <li class="list-group-item" style="background-color: #efe93e7c  "><button type="button"  class="btn  "   style="color: #1d3563" data-mdb-ripple-color="dark"><a  style="color: black;" href="https://medicalevents.tv/cm_ups/agendas/2024/01/28/YQRDH16MPFfeda9BRqH3ZcZnPc8RxoNkYPBYEfPI.pdf" target="_blank">Program</a></button></li>
            <li class="list-group-item" style="background-color: #228350 "><button type="button"  class="btn  "  style="color: #1d3563" data-mdb-ripple-color="dark"><a  style="color: white;" href="#footer">Registration</a></button></li>
        
        
           
  
            <li>
             
            </li>
            

            <!--                <li><a class="nav-link scroll" href="#tickets">Tickets</a>
              </li>-->

            <!--                <li class="mb-4 mb-lg-0"><a class="nav-link scroll" href="#faq">FAQ</a>
              </li>-->
          </ul>
        </div>
          <!--   <ul>
            <a
            class="scroll btn btn-primary btn-lg mb-5 mb-lg-0" style="background-color: #fbd170;border-color: #fbd170; color: #717171;"
            [routerLink]="['./', 'register']"
          >
          Registration
          </a>
           </ul>-->
        </div>
    
          
     
      </nav>

    </div>
    <!--End container-->
  </div>
</header>
