<!-- Preloader -->
<!-- <div class="loader"> -->
<!-- Preloader inner -->
<!-- <div class="loader-inner">
    <svg
      width="120"
      height="220"
      viewbox="0 0 100 100"
      class="loading-spinner"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="spinner"
        cx="50"
        cy="50"
        r="21"
        fill="#ffffff"
        stroke-width="6"
      />
    </svg>
  </div> -->

<!-- End preloader inner -->
<!-- </div> -->
<!-- <mat-spinner></mat-spinner> -->
<!-- End preloader-->
<!--Wrapper-->
<!-- <div class="wrapper"> -->

<!--Hero section-->
<section class="gradient-overlay gradient-overlay-blue">
  <img class="bg-image" src="assets/img/alex.jpg" alt="EGSCOE 2024- Alexandria" />
  

  <!--Container-->
  
  <div class="container">
    <!--Row-->
    <div
      class="
        row
        spacer-lg-bottom spacer-double-sm
        align-items-center
        z-index-2
        position-relative
      "
    >
      <div class="col-12">
       
      
        <br>
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-9 col-md-10 text-center ">
            <br> <br><br>
            
      
          <br><br>
          <div class="text-center " style="color: #175f36;background-color: rgba(255, 255, 255, 0.527);"><h1>7th International Conference of EGSCOE</h1><h2>Foresight for Young Ophthalmologists</h2> <br>
          
          <br><br>
          <img src="assets/event/logobody.png" alt="EGSCOE"  style="width: 270px;" class="rounded img-fluid" /><br>
          <br>
          <h3 style="color:white;background-color: #175f36"><h4>Hilton Kings Ranch Alexandria</h4> <h4>8th-10th February,2024</h4></h3>
  
                

        <div
        class="countdown text-center card card-body bg-white-20 border-0 flex-row flex-wrap justify-content-around"
       style="color: #175f36;"
      >
        <div class="p-2">
          <span class="counter mb-1"style="color: #175f36;">{{ days }}</span>
          <span class="label"style="color: #175f36;">Day</span>
        </div>
        <div class="p-2">
          <span class="counter mb-1"style="color: #175f36;">{{ hours }}</span>
          <span class="label"style="color: #175f36;">Hour</span>
        </div>
        <div class="p-2">
          <span class="counter mb-1"style="color: #175f36;">{{ mins }}</span>
          <span class="label"style="color: #175f36;">Minutes</span>
        </div>
        <div class="p-2">
          <span class="counter mb-1"style="color: #175f36;">{{ secs }}</span>
          <span class="label"style="color: #175f36;">Seconds</span>
        </div>
      </div>
        </div>
   
<br><br><br><br>
        
  
       
        
        </div>
        </div>
       
      </div>
     
    </div>
    <!--End row-->
  </div>
  <!--End container-->
  <!--Container-->
  <div class="container">
    <!--
    <div class="row z-index-2 position-relative align-items-center">
      <div class="col-sm-8 col-md-6 col-lg-4 offset-lg-0 mb-5 mb-lg-0">
        <div class="media align-items-center">
          <img class="max-width-md mr-3" src="assets/svg/map-icon.svg" alt="" />
          <div class="media-body">
            <h2 class="h5 text-white mb-0">Location</h2>
            <p class="text-light mb-0">Conrad Hotel, Abu Dhabi, UAE</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 mb-5 mb-lg-0 text-center">
        <div class="media align-items-center">
       
       
        </div>
      </div>  
      <div class="col-md-6 col-lg-4 text-lg-end">
        <a
          class="scroll btn btn-primary btn-lg mb-5 mb-lg-0"
          [routerLink]="['./', 'register']"
        >
          Registration
        </a>
      </div>
    </div>--->
    <!--End row-->
   
  </div>
  <!--End container-->

  <!--<div class="curved-decoration splash">
    <svg
      version="1.1"
      class="bg-white-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 5492.1021 581.185"
      enable-background="new 0 0 5492.1021 581.185"
      xml:space="preserve"
    >
      <path
        d="M5492.1021,0v581.185H0.0054c-0.0068,0-0.0073-0.01-0.0006-0.0107L5492.1021,0z"
      />
    </svg>
  </div>-->
</section>
<!--End hero section-->

<!--About section-->
<!--End about section-->
<app-message></app-message>
<!--Video cover section-->
<section class="jarallax gradient-overlay gradient-overlay-dark">
  <!--<div class="curved-decoration img-decoration img-decoration-top">
    <svg
      version="1.1"
      class="bg-white-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2560 221"
      enable-background="new 0 0 2560 221"
      xml:space="preserve"
    >
      <polygon points="0,0 2560,0 0,221 " />
    </svg>
  </div>-->

  <!--Container-->
 <!-- <div class="container spacer-double-lg z-index-2 position-relative">-->
   
    <!--Row-->
    <!--<div class="row justify-content-center text-center">
      <div class="col-md-8 col-lg-6">
      
        <a (click)="openDialog('7CiIpPoAdGs', 'vedio')">
          <span class="play-icon play-icon-md">
            <span class="play-icon-inner">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 142.7828 161.2558"
                enable-background="new 0 0 142.7828 161.2558"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M0.0173,80.1791C0.0176,56.5765-0.0355,32.9736,0.0429,9.3712c0.0329-9.9065,3.0932-11.661,11.832-6.6662
    c41.4524,23.6929,82.8727,47.4424,124.2598,71.2493c8.8638,5.0987,8.8551,8.208,0.0293,13.2873
    c-41.127,23.669-82.2835,47.2866-123.4601,70.8691c-9.8028,5.6142-12.6491,3.9899-12.672-7.1236
    C-0.0169,127.3846,0.0169,103.7818,0.0173,80.1791z"
                  />
                </g>
              </svg>
            </span>
          </span>
        </a>
      </div>
    </div>-->
    <!--End row-->

  <!--End container-->
  
</section>
<app-society2></app-society2>
<!--End video cover section-->
<!--<app-speaker4></app-speaker4>-->



<!--Countdown section-->

<!--End countdown section-->

<!--Speakers section-->

<!--End speakers section-->
<!--Schedule section-->
<!-- Gad 2021-2-21class="bg-secondary" -->

<!--<app-accomodation></app-accomodation>-->
<!--End schedule section-->

<!--Tickets section-->
<!-- <section id="tickets" class="bg-white z-index-2">

  <div class="container spacer-double-sm">

    <div class="row">
      <div class="col">
        <div class="mb-5 pb-5 text-center">
          <span class="icon-title icon-bg-dark rounded-circle mb-3">
            <img
              class="icon-title-inner"
              src="assets/svg/ticket-icon.svg"
              alt=""
            />
          </span>
          <h2 class="h1">Conference Tickets</h2>
          <p class="w-md-60 mx-auto mb-0 lead">
            If you want more bookings, then reserve your e-ticket for the ride
            of the year Come join the world’s influencers and get answers.
          </p>
        </div>
      </div>
    </div>
 
    <div class="row justify-content-center text-center">
      <div class="col-12 col-md-5">
        <div class="card rounded-lg shadow-lg mb-5 mb-md-0 border-0">
          <div class="card-body py-6">
            <div class="row justify-content-center">
              <div class="col-12 col-xl-9">
                <div class="text-center mb-5">
                  <span class="badge badge-pill badge-soft-green">
                    <span class="font-weight-500 text-uppercase"
                      >Standard pass</span
                    >
                  </span>
                </div>
                <div class="d-flex justify-content-center">
                  <span class="h2 mb-0 mt-lg-2">$</span>
                  <span class="display-3">129</span>
                </div>
                <p class="text-center text-muted mb-6 mb-md-8">1 Days ticket</p>
                <div class="d-flex">
                  <div class="badge badge-rounded badge-soft-green mr-4">
                    <i class="fa fa-check"></i>
                  </div>
                  <p>Main Event speeche</p>
                </div>
                <div class="d-flex">
                  <div class="badge badge-rounded badge-soft-green mr-4">
                    <i class="fa fa-check"></i>
                  </div>
                  <p>All areas access</p>
                </div>
                <div class="d-flex">
                  <div class="badge badge-rounded badge-soft-green mr-4">
                    <i class="fa fa-check"></i>
                  </div>
                  <p>Lunch on day 1</p>
                </div>
              </div>
            </div>
          </div>
          <a
            [routerLink]="['./', 'register']"
            class="radius-tl radius-tr btn btn-block btn-lg btn-primary rounded-bottom"
          >
            Buy ticket
          </a>
        </div>
      </div>
      <div class="col-12 col-md-5">
        <div class="card rounded-lg shadow-lg border-0">
          <div class="card-body py-6">
            <div class="row justify-content-center">
              <div class="col-12 col-xl-9">
                <div class="text-center mb-5">
                  <span class="badge badge-pill badge-soft-blue">
                    <span class="font-weight-500 text-uppercase"
                      >Silver pass</span
                    >
                  </span>
                </div>
                <div class="d-flex justify-content-center">
                  <span class="h2 mb-0 mt-lg-2">$</span>
                  <span class="display-3">329</span>
                </div>
                <p class="text-center text-muted mb-6 mb-md-8">
                  All Days ticket
                </p>
                <div class="d-flex">
                  <div class="badge badge-rounded badge-soft-blue mr-4">
                    <i class="fa fa-check"></i>
                  </div>
                  <p>Main Event speeche</p>
                </div>
                <div class="d-flex">
                  <div class="badge badge-rounded badge-soft-blue mr-4">
                    <i class="fa fa-check"></i>
                  </div>
                  <p>Limited areas access</p>
                </div>
                <div class="d-flex">
                  <div class="badge badge-rounded badge-soft-blue mr-4">
                    <i class="fa fa-check"></i>
                  </div>
                  <p>All days lunch</p>
                </div>
              </div>
            </div>
          </div>
          <a
            [routerLink]="['./', 'register']"
            class="radius-tl radius-tr btn btn-block btn-lg btn-secondary rounded-bottom"
          >
            Buy ticket
          </a>
        </div>
      </div>
    </div>

  </div>

</section> -->
<!--End tickets section-->
<!--Registration section-->
<!-- <section id="register" class="jarallax gradient-overlay gradient-overlay-dark">
  <div class="curved-decoration img-decoration img-decoration-top">
    <svg
      version="1.1"
      class="bg-white-svg"
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2560 221"
      enable-background="new 0 0 2560 221"
      xml:space="preserve"
    >
      <polygon points="0,0 2560,0 0,221 " />
    </svg>
  </div>
  <img class="jarallax-img" src="assets/img/1.jpg" alt="" />

  <div class="container z-index-2 position-relative spacer-double-md">
    <div class="row justify-content-lg-between align-items-lg-center">
      <div class="col-lg-5 mb-5 mb-lg-0">
        <span class="d-block text-white text-uppercase span-style mb-2"
          >20 to 22 january 2022</span
        >
        <h1 class="display-4 text-white mb-0">
          Register your coming at Adore Conference
        </h1>
      </div>
      <div class="col-lg-5">
        <div class="bg-white rounded p-5">
          <form>
            <div class="mb-4">
              <h2 class="h4">Register your coming</h2>
            </div>
            <div class="mb-3">
              <div class="input-group input-group form">
                <input
                  type="text"
                  class="form-control"
                  name="username"
                  placeholder="Enter your username"
                />
              </div>
            </div>
            <div class="mb-3">
              <div class="input-group input-group form">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  placeholder="Enter your email address"
                />
              </div>
            </div>
            <div class="mb-3">
              <div class="js-focus-state input-group input-group form">
                <select class="form-select custom-select">
                  <option selected="">Choose a ticket</option>
                  <option value="budget">One day ticket</option>
                  <option value="budget">All days ticket</option>
                </select>
              </div>
            </div>
            <div class="d-grid gap-2">
              <button class="btn btn-primary" type="submit">
                Get your ticket
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="curved-decoration img-decoration img-decoration-bottom">
    <svg
      version="1.1"
      class="bg-secondary-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2560 221"
      enable-background="new 0 0 2560 221"
      xml:space="preserve"
    >
      <polygon points="2560,221 0,221 2560,0 "></polygon>
    </svg>
  </div>
</section> -->
<!--End registration section-->
<!--Venue section-->
<!--Gad 21-2-2021 class="bg-secondary" -->
<!-- <section id="venue" style="background-color: #f5f8fb">
 
  <div class="container spacer-double-sm">

    <div class="row">
      <div class="col">
        <div class="mb-5 pb-2 text-center">
          <span class="icon-title icon-bg-dark rounded-circle mb-3">
            <img
              class="icon-title-inner"
              src="assets/svg/hotel-icon.svg"
              alt="Image Description"
            />
          </span>
          <h2 class="h1">Accommodation</h2>
          
        </div>
      </div>
    </div>
  
    <div class="row">
      <div class="col-lg-6 mb-5 mb-lg-0">
        <div class="row">
          <div class="col-sm-6 mb-4 mb-sm-0">
            <h2 class="h5">Le Meridien Hotel, Dubai</h2>
            <address class="mb-0">
              <ul class="list-unstyled text-secondary mb-2">
                <li class="py-1">
                  Al Garhoud Road - Dubai - United Arab Emirates
                </li>
                <li class="py-1">(971) 544 - 0110</li>
              </ul>
              <a href="#" class="hover-arrow text-blue"
                >Location <span class="fa fa-arrow-right"></span
              ></a>
            </address>
          </div>
          <div class="col-sm-6">
            <img class="img-fluid rounded" src="assets/img/2.jpg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-sm-6 mb-4 mb-sm-0">
            <h2 class="h5">Roda Al Bustan</h2>
            <address class="mb-0">
              <ul class="list-unstyled text-secondary mb-2">
                <li class="py-1">
                  Casablanca St - Dubai - United Arab Emirates
                </li>
                <li class="py-1">(971) 394 - 3598</li>
              </ul>
              <a href="#" class="hover-arrow text-blue"
                >Location <span class="fa fa-arrow-right"></span
              ></a>
            </address>
          </div>
          <div class="col-sm-6">
            <img class="img-fluid rounded" src="assets/img/5.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  
    <div class="row">
      <div class="col">
        <div class="mb-5 pb-4 mt-5 pt-4">
          <hr />
        </div>
      </div>
    </div>
  
  </div>

  <div class="curved-decoration">
    <svg
      version="1.1"
      class="bg-white-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2560 221"
      enable-background="new 0 0 2560 221"
      xml:space="preserve"
    >
      <polygon points="2560,221 0,221 2560,0 "></polygon>
    </svg>
  </div>
</section> -->
<!--End venue section-->
<!--Gallery section-->

<!--Video Library section-->
<!--Gad 21-2-2021 class="bg-secondary" -->

<!--End topics section-->
<!--End gallery section-->
<!--Faq section-->
<!--    <section id="faq" class="bg-secondary">
      Container
      <div class="container spacer-double-sm">
        Row
        <div class="row">
          <div class="col">
            <div class="mb-5 pb-5 text-center"> <span class="icon-title icon-bg-dark rounded-circle mb-3">
              <img class="icon-title-inner" src="assets/svg/faq-icon.svg" alt="">
            </span>
              <h2 class="h1">Frequently asked questions </h2>
              <p class="w-md-60 mx-auto mb-0 lead">Some frequently asked questions for you.</p>
            </div>
          </div>
        </div>
        End row
        Row
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div id="faq-accordion">
              <div class="card card-collapse mb-3 ">
                <div class="card-header accordion-header">
                  <h5 class="mb-0">
                                 <button class="btn btn-link btn-block d-flex justify-content-between accordion-collapse-btn collapsed" data-toggle="collapse" data-target="#faq-accordion-1" aria-expanded="false" aria-controls="faq-accordion-1">
                                 What is forex trading ?
                                 <span class="accordion-arrow">
                                 <span class="fa fa-angle-down accordion-arrow-inner"></span>
                                 </span>
                                 </button>
                              </h5>
                </div>
                <div id="faq-accordion-1" class="collapse" data-parent="#faq-accordion">
                  <div class="card-body accordion-body">Web marketing refers to a broad category of advertising that takes many different forms, but generally involves any marketing activity conducted online. Marketers have shifted their efforts online because it tends to be significantly less expensive.</div>
                </div>
              </div>
              <div class="card card-collapse mb-3">
                <div class="card-header accordion-header">
                  <h5 class="mb-0">
                                 <button class="btn btn-link btn-block d-flex justify-content-between accordion-collapse-btn collapsed" data-toggle="collapse" data-target="#faq-accordion-2" aria-expanded="false" aria-controls="faq-accordion-2">
                                 It is worth trading forex ?
                                 <span class="accordion-arrow">
                                 <span class="fa fa-angle-down accordion-arrow-inner"></span>
                                 </span>
                                 </button>
                              </h5>
                </div>
                <div id="faq-accordion-2" class="collapse" data-parent="#faq-accordion">
                  <div class="card-body accordion-body">Web marketing refers to a broad category of advertising that takes many different forms, but generally involves any marketing activity conducted online. Marketers have shifted their efforts online because it tends to be significantly less expensive.</div>
                </div>
              </div>
              <div class="card card-collapse mb-3 ">
                <div class="card-header accordion-header">
                  <h5 class="mb-0">
                                 <button class="btn btn-link btn-block d-flex justify-content-between accordion-collapse-btn collapsed" data-toggle="collapse" data-target="#faq-accordion-3" aria-expanded="false" aria-controls="faq-accordion-3">
                                 Who is the richest forex trader ?
                                 <span class="accordion-arrow">
                                 <span class="fa fa-angle-down accordion-arrow-inner"></span>
                                 </span>
                                 </button>
                              </h5>
                </div>
                <div id="faq-accordion-3" class="collapse" data-parent="#faq-accordion">
                  <div class="card-body accordion-body">Web marketing refers to a broad category of advertising that takes many different forms, but generally involves any marketing activity conducted online. Marketers have shifted their efforts online because it tends to be significantly less expensive.</div>
                </div>
              </div>
              <div class="card card-collapse mb-3">
                <div class="card-header accordion-header">
                  <h5 class="mb-0">
                                 <button class="btn btn-link btn-block d-flex justify-content-between accordion-collapse-btn collapsed" data-toggle="collapse" data-target="#faq-accordion-4" aria-expanded="false" aria-controls="faq-accordion-4">
                                 Can you get rich by trading forex ?
                                 <span class="accordion-arrow">
                                 <span class="fa fa-angle-down accordion-arrow-inner"></span>
                                 </span>
                                 </button>
                              </h5>
                </div>
                <div id="faq-accordion-4" class="collapse" data-parent="#faq-accordion">
                  <div class="card-body accordion-body">Web marketing refers to a broad category of advertising that takes many different forms, but generally involves any marketing activity conducted online. Marketers have shifted their efforts online because it tends to be significantly less expensive.</div>
                </div>
              </div>
              <div class="card card-collapse">
                <div class="card-header accordion-header ">
                  <h5 class="mb-0">
                                 <button class="btn btn-link btn-block d-flex justify-content-between accordion-collapse-btn collapsed" data-toggle="collapse" data-target="#faq-accordion-5" aria-expanded="false" aria-controls="faq-accordion-5">
                                 How much money od i need to trade ?
                                 <span class="accordion-arrow">
                                 <span class="fa fa-angle-down accordion-arrow-inner"></span>
                                 </span>
                                 </button>
                              </h5>
                </div>
                <div id="faq-accordion-5" class="collapse" data-parent="#faq-accordion">
                  <div class="card-body accordion-body">Web marketing refers to a broad category of advertising that takes many different forms, but generally involves any marketing activity conducted online. Marketers have shifted their efforts online because it tends to be significantly less expensive.</div>
                </div>
              </div>
            </div>
            <div class="w-75 mx-auto mt-4 text-center">
              <p class="mb-0">Didn't find what you look after ? Visit the <a href="#" class="hover-arrow text-blue">Help Center
                <span class="fa fa-arrow-right"></span></a>
              </p>
            </div>
          </div>
        </div>
        End row
      </div>
    </section>-->
<!--End faq section-->
<!--Newsletter section-->
<!--    <section class="bg-primary-3 newsletter">
      Container
      <div class="container z-index-2 position-relative ">
        Row
        <div class="row justify-content-center align-items-lg-center">
          <div class="col-lg-10">
            <div class="bg-white shadow-lg rounded p-lg position-relative mt-n text-center o-hidden">
              <div class="bg-decoration-container">
                <div class="bg-decoration" data-jarallax-element="150 0">
                  <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 749.2 848.7" enable-background="new 0 0 749.2 848.7;" xml:space="preserve">
                    <path fill="#F5F8FB" d="M184.3,0.4C93.2,6.1,16.9,72,7.8,162.8C-4.8,287.3-8.4,460.4,46.3,573.6c30.8,63.7,83.4,124.3,140.1,176.9
  C327,880.8,537.4,873,702.9,776.3l0,0c71.4-41.7,56-159.5-17.3-197.7c-91.8-47.8-180.8-119.3-194.7-219.9c-1.2-9-2.3-17.8-3.2-26.6
  C472.3,174,343-9.5,184.3,0.4L184.3,0.4z" />
                  </svg>
                </div>
              </div>
              <div class="z-index-2 position-relative">
                <h2 class="mb-5 h1">Subsribe now to get event news</h2>
                <form>
                  <div class="form-row">
                    <div class="col-sm-8 ">
                      <div class=" input-group form mb-3 mb-lg-0">
                        <input class="form-control" name="email" placeholder="Email" type="email">
                      </div>
                    </div>
                    <div class="col-sm-4 mb-lg-0 mb-2">
                      <button type="submit" class="btn btn-block btn-primary">Subscribe</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        End row
      </div>
      End conatiner
    </section>-->
<!--End newsletter section-->
<!--Footer-->

<!--End footer section-->
<!--To the top-->
<!-- <a class="scroll-to-top scroll rounded-circle" href="#wrapper"
    ><span class="fas fa-arrow-up top-icon"></span
  ></a> -->
<!-- End to the top -->
<!-- </div> -->
<!-- End wrapper-->
